<template>
  <div class="container-background">
    <div>
      <div
        @click="$router.push({ name: 'Login' })"
        style="width: fit-content"
        class="cursor-pointer display-flex align-items-center ml-6"
      >
        <v-btn class="button-return-icon" icon>
          <v-icon color="#283C4D" size="16px"> mdi-arrow-left </v-icon>
        </v-btn>
        <p class="button-return-text mon-regular hover-underline ml-2">
          {{ texts.signup.textButtonReturn }}
        </p>
      </div>
    </div>
    <div class="display-flex align-items-center justify-content-center mt-5">
      <div @click="home" class="content-image cursor-pointer">
        <img src="@/assets/icons/logo.png" alt="" />
      </div>
    </div>
    <div
      class="display-flex align-items-center justify-content-center mt-5 mb-5"
    >
      <p class="text-title mon-bold">{{ texts.signup.textTitle }}</p>
    </div>
    <div>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <div>
              <v-text-field
                v-model="enterpriseName"
                type="text"
                :label="texts.signup.textEnterpriseName"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textEnterpriseName"
                persistent-placeholder
                maxlength="60"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-select
                v-model="enterpriseCountry"
                :items="itemsCountries"
                item-text="sName"
                item-value="sCountryId"
                :label="texts.signup.textEnterpriseCountry"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textEnterpriseCountry"
                @input="selecState"
              >
              </v-select>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <v-select
                v-model="enterpriseState"
                :items="itemsStates"
                item-text="sName"
                item-value="sStateId"
                :label="texts.signup.textEnterpriseState"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textEnterpriseState"
                @input="selecState"
              >
              </v-select>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-text-field
                v-model="name"
                type="text"
                :label="texts.signup.textName"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textName"
                persistent-placeholder
                maxlength="40"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-text-field
                v-model="lastName"
                type="text"
                :label="texts.signup.textLastName"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textLastName"
                persistent-placeholder
                maxlength="40"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-text-field
                v-model="email"
                type="email"
                :label="texts.signup.textEmail"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textEmail"
                persistent-placeholder
                maxlength="85"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-text-field
                v-model="phoneNumber"
                type="text"
                :label="texts.signup.textPhone"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textPhone"
                persistent-placeholder
                maxlength="13"
                @keypress="soloNumbers"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-text-field
                v-model="password"
                :label="texts.signup.textPassword"
                :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (bShowPassword = !bShowPassword)"
                :type="bShowPassword ? 'text' : 'password'"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textPassword"
                persistent-placeholder=""
                autocomplete="new-password"
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-text-field
                v-model="confirmPassword"
                :label="texts.signup.textConfirmPassword"
                :append-icon="bShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="
                  () => (bShowConfirmPassword = !bShowConfirmPassword)
                "
                :type="bShowConfirmPassword ? 'text' : 'password'"
                outlined
                color="#000000"
                dense
                class="global-inputs mon-regular"
                :placeholder="texts.signup.textConfirmPassword"
                persistent-placeholder=""
                autocomplete="new-password"
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="display-flex align-items-center mt-5">
      <v-btn class="button-cancel mon-regular" @click="home" elevation="0">
        {{ texts.signup.textButtonCancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :loading="bLoading"
        :disabled="!validateForm"
        class="button-signup mon-bold"
        @click="singup"
        elevation="0"
      >
        {{ texts.signup.textButtonRegister }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignupFormLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      itemsCountries: [],
      itemsStates: [],
      enterpriseName: "",
      enterpriseCountry: "",
      enterpriseState: "",
      name: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      bShowPassword: false,
      bShowConfirmPassword: false,
      bLoading: false,
    };
  },
  beforeMount() {
    this.getCountries();
  },
  methods: {
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    singup: function () {
      const self = this;
      this.bLoading = true;
      if (
        this.password !== this.confirmPassword ||
        this.confirmPassword !== this.password
      ) {
        this.bLoading = false;
        this.mixError(self.texts.signup.textSamePassword);
      } else {
        const payload = {
          oEnterprise: {
            sName: this.enterpriseName,
            sLocationStateId: this.enterpriseState,
          },
          oVendor: {
            sName: this.name,
            sLastname: this.lastName,
            sEmail: this.email,
            sPhoneNumber: this.phoneNumber,
            sPassword: this.confirmPassword,
          },
        };

        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises`,
          payload
        )
          .then((response) => {
            this.bLoading = false;
            this.mixSuccess(response.data.message);
            setTimeout(function () {
              self.$router.push("/login");
            }, 1000);
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    home: function () {
      this.$router
        .push({
          name: "Home",
        })
        .catch(() => {});
    },
    getCountries: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.itemsCountries = response.data.results;
          this.enterpriseCountry = response.data.results[0].sCountryId;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function () {
      this.state = "";
      this.getStates();
    },
    getStates: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.enterpriseCountry}/states`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          // this.enterpriseState = response.data.results[0].sStateId;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.enterpriseName !== "" &&
        this.enterpriseCountry !== "" &&
        this.enterpriseState !== "" &&
        this.name !== "" &&
        this.lastName !== "" &&
        this.phoneNumber !== "" &&
        this.email !== "" &&
        this.password !== "" &&
        this.confirmPassword !== "" &&
        this.enterpriseName !== null &&
        this.enterpriseCountry !== null &&
        this.enterpriseState !== null &&
        this.name !== null &&
        this.lastName !== null &&
        this.phoneNumber !== null &&
        this.email !== null &&
        this.password !== null &&
        this.confirmPassword !== null
      );
    },
  },
  watch: {},
};
</script>

<style scoped>
.hover-underline:hover {
  text-decoration: underline;
}

.button-return-icon {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  width: 25px !important;
  height: 25px !important;
}

.button-return-text {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.button-signup {
  height: 45px !important;
  width: 48%;
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-cancel {
  height: 45px !important;
  width: 48%;
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.text-title {
  text-align: center;
  font-size: 33px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.container-background {
  width: 100%;
}

.content-image {
  width: 180px;
  height: 130px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .text-title {
    font-size: 25px;
  }
  .container-background {
    padding: 25px 0px;
  }
}
</style>